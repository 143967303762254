import React, { useEffect } from 'react';
import sendWebVitalsData from '@ctrip/nfes-vitals';
import { registerServiceWorker, unregisterServiceWorker } from '@/src/utils/registerWorker';
import { CaptchaScript, HotelCaptchaScript, JigsawCaptchaScript } from './Script';
import { CoffeebeanScript } from './CoffeebeanScript';
import { PCLoginScript } from './PCLoginScript';

type BootstrapProps = {
	GlobalSwitches: Record<string, boolean>;

	useAres: boolean;
	aresModules: {
		activeVersion: string;
		canaryVersion: string;
		group: string;
		name: string;
		versionRange: string;
	};
	aresManifest: Record<string, string>;
	aresAssetPrefix: string;

	register?: boolean;
	runtimeEnv: string;
	coffeebeanUrl: string;
	hotelCaptchaUrl: string;
};

const Bootstrap = ({
	useAres,
	aresModules,
	aresManifest,
	aresAssetPrefix,

	register = true,
	GlobalSwitches,

	runtimeEnv,
	coffeebeanUrl,
	hotelCaptchaUrl,
}: BootstrapProps) => {
	useEffect(() => {
		console.info('[Ares] version', aresModules.canaryVersion);
		console.info('[Ares] assetPrefix', aresAssetPrefix);
		console.info('[Ares] modules', aresModules);
		console.info('[Ares] manifest', aresManifest);
	}, [aresAssetPrefix, aresModules, aresManifest]);

	useEffect(() => {
		// 正常的首屏加载逻辑执行完
		sendWebVitalsData();
	}, []);

	useEffect(() => {
		try {
			if (['flights.ctrip.com'].includes(location.host) && 'serviceWorker' in navigator) {
				if (register) {
					registerServiceWorker();
				} else {
					unregisterServiceWorker();
				}
			}
		} catch (error) {
			console.error(error);
		}
	});

	return (
		<>
			{useAres && (
				<script
					type="text/javascript"
					// eslint-disable-next-line react/no-danger
					dangerouslySetInnerHTML={{
						__html: `
                        try{
                            window['aresAssetPrefix'] = "${aresAssetPrefix}";
                            window['aresManifest'] = ${JSON.stringify(aresManifest)};
                            window['aresModules'] = ${JSON.stringify(aresModules)};
                        } catch (error){
                            console.log(error);
                        }`,
					}}
				/>
			)}
			<script
				type="text/javascript"
				// eslint-disable-next-line react/no-danger
				dangerouslySetInnerHTML={{
					__html: `
                        try{
                            window['vetSwitcherWeb'] = String(${GlobalSwitches?.vetSwitcherWeb});
                            window['startTime'] = new Date().getTime();
                            window['$_bf'] = { loaded: true };
                        } catch (error){
                            console.log(error);
                        }`,
				}}
			/>
			{/* <UbtScript /> */}
			<CoffeebeanScript {...{ runtimeEnv, coffeebeanUrl }} />
			<CaptchaScript />
			<JigsawCaptchaScript {...{ runtimeEnv }} />
			<PCLoginScript {...{ runtimeEnv }}></PCLoginScript>
			<HotelCaptchaScript {...{ hotelCaptchaUrl }} />
		</>
	);
};

export default Bootstrap;
