const getPVData = (firstSegments) => {
	const GlobalSearchCriteria = window?.GlobalSearchCriteria || {};
	const {
		flightSegments = [],
		flightWay = 'S',
		adultCount = 1,
		childCount = 0,
		infantCount = 0,
		cabin = '',
	} = GlobalSearchCriteria;

	const segmentinfo = flightSegments.map((section, index) => {
		// 如果firstSegments有值代表往返返程，去程的信息已经拿到了，直接用
		const segments = index === 0 && firstSegments?.length ? firstSegments : [section];
		const isTransfer =
			segments.length > 1
				? segments.some((s) => s.stops?.length > 0)
					? 'TransitWithStop'
					: 'Transfer'
				: segments[0]?.stops?.length > 0
				? 'Stop'
				: 'Direct';
		return {
			segmentno: index + 1,
			isTransfer,
			segments: segments.map((segment, segmentno) => {
				const {
					flightNo = '',
					sharedFlight = '',
					departureCityCode,
					arrivalCityCode,
					stops = [],
					departureDateTime = '',
					arrivalDateTime = '',
				} = segment;
				return {
					sequence: segmentno + 1,
					flightno: flightNo,
					shareflightno: sharedFlight,
					airlinetype: '',
					dport: departureCityCode,
					aport: arrivalCityCode,
					stopport: stops.map((item) => item.name || '').join(','),
					transporttype: 'Airline',
					takeofftime: departureDateTime,
					arrivaltime: arrivalDateTime,
				};
			}),
		};
	});
	return {
		airlineClass: cabin.replace(/_/g, ''),
		flighttype: flightWay,
		language: 'zh_CN',
		adult: adultCount,
		children: childCount,
		infant: infantCount,
		flightinformation: {
			segmentinfo,
		},
	};
};

export default getPVData;
