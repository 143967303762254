import React, { PropsWithChildren } from 'react';
import { OfflineInfo } from '@/typings/offlineInfo';

type Props = {
	offlineInfo?: OfflineInfo;
	header?: string;
	footer?: string;
	topLayer?: string;
};

const HeaderFooter = ({ offlineInfo, header = '', footer = '', topLayer = '', children }: PropsWithChildren<Props>) => {
	const { validUser = false, operatorId, currentUserId, targetUserId } = offlineInfo || {};
	return (
		<>
			{!validUser && <div id="hp_header" dangerouslySetInnerHTML={{ __html: header }} />}
			<div id="hp_container">
				{!validUser && <div id="hp_top_layer" dangerouslySetInnerHTML={{ __html: topLayer }} />}
				{validUser && (
					<div style={{ textAlign: 'center' }}>
						操作员 {operatorId}
						操作员UID {currentUserId}
						客人UID {targetUserId}
					</div>
				)}
				{children}
				{!validUser && <div id="hp_footer" dangerouslySetInnerHTML={{ __html: footer }} />}
			</div>
		</>
	);
};

export default HeaderFooter;
