export const ssrWindow = (function () {
	return typeof window !== 'undefined'
		? window
		: ({
				location: {
					hash: '',
					host: '',
					hostname: '',
					href: '',
					origin: '',
					pathname: '',
					protocol: '',
					search: '',
				},
				screen: {
					width: 390,
					height: 844,
				},
				devicePixelRatio: 1,
				addEventListener: (() => {}) as unknown,
		  } as Window);
})();

export default ssrWindow;
