import { sendMetric } from '../sources/common/lightUbt';

const monitorApis = ['batchSearch'];

export default class PerformanceMonitor {
	constructor() {
		this.reportedApis = [];
		this.init();
	}

	static mark = (name) => {
		if (!window.performance || !window.PerformanceObserver) {
			return;
		}
		window.performance.mark(name);
	};

	init = () => {
		if (!window.performance || !window.PerformanceObserver) {
			return;
		}
		const timer = setTimeout(this.collectMarks, 15000);
		window.addEventListener('beforeunload', () => {
			const basePerformanceInfo = this.getBasePerformanceData();
			this.report('base_performance', basePerformanceInfo);
			this.collectMarks();
			clearTimeout(timer);
		});
		this.observe();
	};

	getBasePerformanceData = () => {
		const reportData = {};
		if (performance.getEntriesByType('navigation').length > 0) {
			const navigationTiming = performance.getEntriesByType('navigation')[0];
			const {
				loadEventEnd,
				domInteractive,
				domComplete,
				responseEnd,
				// responseStart,
				domainLookupEnd,
				connectStart,
				connectEnd,
				redirectStart,
				redirectEnd,
			} = navigationTiming;
			// 页面加载完成时间
			reportData.loadPage = loadEventEnd;
			// html响应耗时: 从navigateStart到html下载完成
			reportData.htmlEnd = responseEnd;
			// dns查询耗时
			reportData.dns = domainLookupEnd;
			// Tcp耗时
			reportData.tcp = connectEnd - connectStart;
			// domInteractive
			reportData.domInteractive = domInteractive;
			// 重定向时间
			reportData.redirect = redirectEnd - redirectStart;
			// dom
			reportData.domComplete = domComplete;
		}
		Object.keys(reportData).forEach((key) => {
			reportData[key] = Math.floor(reportData[key]);
		});
		return reportData;
	};

	report = (key, value) => {
		sendMetric(key, value);
	};

	observe = () => {
		const reportApiTime = (entries) => {
			for (let i = 0; i < entries.length; i++) {
				const entry = entries[i];
				// 监测第一次发起的时间，和响应的时间。后续只监测响应时间
				const apiName = monitorApis.find((v) => entry.name.includes(v));
				if (apiName) {
					if (!this.reportedApis.includes(apiName)) {
						this.reportedApis.push(apiName);
						const { fetchStart, duration } = entry;
						this.report(apiName + '_fetchStart', { fetchStart, duration });
					}
					const duration = entry.duration;
					this.report(apiName + '_duration', duration);
				}
			}
		};
		const initialEntries = performance.getEntries();
		reportApiTime(initialEntries);

		const observer2 = new PerformanceObserver((list) => {
			reportApiTime(list.getEntries());
		});
		observer2.observe({ entryTypes: ['resource'] });
	};

	collectMarks = () => {
		const marks = window.performance.getEntriesByType('mark');
		const nameSet = new Set(marks.map((i) => i.name));
		nameSet.forEach((name) => {
			const sameNamePerformanceMarks = marks.filter((mark) => mark.name === name);
			if (sameNamePerformanceMarks.length !== 2) {
				return;
			}
			const [before, end] = sameNamePerformanceMarks;
			const spend = end.startTime - before.startTime;
			this.report(name + '_mark', spend);
			performance.clearMarks(name);
		});
	};
}
