/* eslint-disable react/no-unknown-property */

import React, { useMemo } from 'react';
import Head from 'next/head';
/**
 * 引入安全拼图组件
 * 滑块组件使用
 */
export const JigsawCaptchaScript = ({ runtimeEnv = 'fat' }) => {
	const captchaScriptUrl = useMemo(() => {
		const host = runtimeEnv.includes('fat') ? 'webresource.uat.qa.nt.ctripcorp.com' : 'webresource.c-ctrip.com';
		return `https://${host}/ares2/infosec/jigsawCaptcha/~2.0.0/default/js/jigsaw-captcha.min.js?expires=1d`;
	}, [runtimeEnv]);
	return <script src={captchaScriptUrl} async crossOrigin="anonymous" />;
};

export const UbtScript = () => {
	const ubtScriptUrl = '//webresource.c-ctrip.com/code/ubt/_bfa.min.js';
	return (
		<>
			<Head>
				<link rel="preload" href={ubtScriptUrl} as="script" crossOrigin="anonymous" />
			</Head>
			<script src={ubtScriptUrl} async crossOrigin="anonymous" />
		</>
	);
};

export const CaptchaScript = () => {
	const captchaScriptUrl = '//webresource.c-ctrip.com/ares2/infosec/captcha/~2.5.8/default/js/captcha.min.js';
	return <script src={captchaScriptUrl} async crossOrigin="anonymous" />;
};

export const HotelCaptchaScript = ({ hotelCaptchaUrl }) => {
	if (!hotelCaptchaUrl) {
		return null;
	}
	return (
		<>
			<Head>
				<link rel="preload" href={hotelCaptchaUrl} as="script" crossOrigin="anonymous" />
			</Head>
			<script src={hotelCaptchaUrl} async crossOrigin="anonymous" />
		</>
	);
};
