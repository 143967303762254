/* eslint-disable */
import React, { useEffect } from 'react';
import Head from 'next/head';
import Bootstrap from '../components/Bootstrap/Bootstrap';
import PerformanceMonitor from '../utils/performanceMonitor';
import HeaderFooter from '../components/HeaderFooter/HeaderFooter';

type AppProps = {
	Component: any;
	pageProps: {
		aresModules: any;
		aresManifest: any;
		aresAssetPrefix: any;
		renderData: {
			useAres: any;
			runtimeEnv: any;
			runtimeServerHost: any;
			header: any;
			footer: any;
			topLayer: any;
			captchaUrl: any;
			GlobalSearchCriteria: any;
			GlobalSwitches: any;
			GlobalConfigs: any;
			offlineInfo?: {
				token: string;
				validUser: boolean;
				operatorId?: string;
				targetUserId?: string;
			};
		};
	};
};

const App = ({ Component, pageProps }: AppProps) => {
	const { aresModules, aresManifest, aresAssetPrefix, renderData } = pageProps;
	const {
		useAres,
		runtimeEnv,
		runtimeServerHost,
		header = '',
		footer = '',
		topLayer = '',
		captchaUrl,
		GlobalSearchCriteria,
		GlobalSwitches,
		GlobalConfigs,
		offlineInfo,
	} = renderData || {};
	const { registerServiceWorker } = GlobalSwitches || {};

	useEffect(() => {
		if (typeof window !== 'undefined') {
			import(/* webpackChunkName: "web-core" */ '@ctrip/web-core').then((module) => {
				module.default.init({ AppID: '100032271' });
			});
		}
		window.offlineInfo = offlineInfo;
		window.runtimeEnv = runtimeEnv;
		window.runtimeServerHost = runtimeServerHost;
		window.GlobalSearchCriteria = GlobalSearchCriteria;
		window.GlobalConfigs = GlobalConfigs;
		window.GlobalSwitches = GlobalSwitches;
		new PerformanceMonitor();
	});

	const { coffeebeanUrlList } = GlobalConfigs || {};

	return (
		<>
			<Head>
				<meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
				<meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
				<meta httpEquiv="x-dns-prefetch-control" content="on" />
				<meta name="renderer" content="webkit" />
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
				/>
				<link
					rel="shortcut icon"
					href="//www.ctrip.com/favicon.ico"
					// @ts-ignore
					mce_href="//www.ctrip.com/favicon.ico"
					type="image/x-icon"
				/>
				<link rel="dns-prefetch" href="//webresource.c-ctrip.com" />
				<link rel="preconnect" href="//webresource.c-ctrip.com" />
				<link rel="dns-prefetch" href="//pic.c-ctrip.com" />
				<link rel="preconnect" href="//pic.c-ctrip.com" />
				<link rel="dns-prefetch" href="//accounts.ctrip.com" />
				<link
					charSet="utf-8"
					rel="stylesheet"
					type="text/css"
					crossOrigin="anonymous"
					href={`https://webresource.c-ctrip.com/ares2/basebiz/accountsresource/^2.0.0/default/css/maskComponent.css?expires=1d`}
				/>
			</Head>
			<HeaderFooter
				{...{
					offlineInfo,
					header,
					footer,
					topLayer,
				}}
			>
				<div className={'app-page-container page-header-footer-v2'}>
					<Component {...pageProps} />
				</div>
			</HeaderFooter>
			<Bootstrap
				{...{
					useAres,
					aresManifest,
					aresAssetPrefix,
					aresModules,
					register: registerServiceWorker,
					runtimeEnv,
					coffeebeanUrl: coffeebeanUrlList,
					hotelCaptchaUrl: captchaUrl,
					GlobalSwitches,
				}}
			/>
		</>
	);
};

export default App;
