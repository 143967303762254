import { isServer } from './environment';

export const detectLightHouse = (): boolean => {
	if (isServer()) {
		return false;
	}
	if (navigator.userAgent.indexOf('Speed Insights') > -1 || navigator.userAgent.indexOf('Chrome-Lighthouse') > -1) {
		return true;
	}
	return false;
};
