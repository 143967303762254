import React, { useEffect } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import ssrWindow from '@/src/utils/ssrWindow';
import { sendTrace } from '@/src/sources/common/lightUbt';

const TRANSACTION_ID_KEY = 'transactionid';

/**
 * 生成唯一一个的guid
 * 其他地方使用，请帮忙移动到common
 */
const guid = () => {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
		const r = (Math.random() * 16) | 0;
		const v = c === 'x' ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
};

/**
 * 获取流水号，如果sessionStorage中没有，则生成一个
 */
const getTransactionID = () => {
	const sessionTransactionId = ssrWindow.sessionStorage.getItem(TRANSACTION_ID_KEY);
	const transactionId = sessionTransactionId || guid();
	if (transactionId !== sessionTransactionId) {
		ssrWindow.sessionStorage.setItem(TRANSACTION_ID_KEY, transactionId.toString());
		sendTrace('o_flt_h5_coffeebean_transaction_id', { transactionId });
	}
	return transactionId;
};

const DEF_CONFIG = {
	source: 'flight-online',
	code: 'CB01e12a2260b949239a8c9bef8c5d8d09',
	sdkMode: 'INSURANCE',
	bizCode: '',
	orderid: '',
};

const FAT_CONFIG = {
	saveNetwork: 'ALL', // 测试环境记录网络请求
	saveAction: true, // 测试环境记录行为
	sdkMode: '', // 测试环境不能使用 INSURANCE
};

export const CoffeebeanScript = ({
	runtimeEnv = 'fat',
	coffeebeanUrl,
}: {
	runtimeEnv: string;
	coffeebeanUrl: string;
}) => {
	const { pathname } = useRouter();
	const isTestEnv = runtimeEnv.includes('fat');

	const jira = isTestEnv ? FAT_CONFIG : {};

	const $cbconfig = {
		...DEF_CONFIG,
		...jira,
	};

	useEffect(() => {
		if (ssrWindow.$tracker?.config) {
			ssrWindow.$tracker.config.transactionId = getTransactionID();
		}
	});

	if (['/channel'].includes(pathname)) {
		return null;
	}

	return (
		<>
			<Head>
				<link rel="preload" href={coffeebeanUrl} as="script" crossOrigin="anonymous" />
			</Head>
			<script
				type="text/javascript"
				// eslint-disable-next-line react/no-danger
				dangerouslySetInnerHTML={{
					__html: `
                        try{
                            window['$cbconfig'] = ${JSON.stringify($cbconfig)};
                        }catch (error){
                            console.log(error);
                        }`,
				}}
			/>
			<script src={coffeebeanUrl} async={!isTestEnv} crossOrigin="anonymous" />
		</>
	);
};
