function once(fn) {
	let exec = false;
	let res;
	return function () {
		if (!exec) {
			exec = true;
			try {
				res = fn.apply(null, arguments);
			} catch (e) {
				exec = false;
				throw e;
			}
		}
		return res;
	};
}

/**
 * 注册service worker
 */
export const registerServiceWorker = async function () {
	const { Workbox } = await import(/* webpackChunkName: "workbox-window" */ 'workbox-window');
	const scriptUrl = '/online/sw.js';
	const wb = new Workbox(scriptUrl);
	wb.addEventListener('activated', (event) => {
		// `event.isUpdate` will be true if another version of the service
		// worker was controlling the page when this version was registered.
		if (!event.isUpdate) {
			console.log('Service worker activated for the first time!');

			// If your service worker is configured to precache assets, those
			// assets should all be available now.
		}
	});
	wb.addEventListener('waiting', () => {
		console.log(
			`A new service worker has installed, but it can't activate` +
				`until all tabs running the current version have fully unloaded.`
		);
	});

	wb.addEventListener('message', (event) => {
		if (event.data.type === 'CACHE_UPDATED') {
			const { updatedURL } = event.data.payload;
			console.log(`A newer version of ${updatedURL} is available!`);
		}
	});
	wb.register();
	const version = await wb.messageSW({ type: 'GET_VERSION' });
	console.log('sw version:', version);
};

/**
 * 注销service worker
 */
export const unregisterServiceWorker = async function () {
	const registrations = await navigator.serviceWorker.getRegistrations();
	if (registrations.length > 0) {
		// delete indexed db
		indexedDB.deleteDatabase('workbox-expiration');
		// delete local cache
		let cacheKeys = await caches.keys();
		for (const key of cacheKeys) {
			caches.delete(key);
		}
		// unregister all registrations of servive worker
		for (const registration of registrations) {
			registration.unregister();
		}
		// reload page without service worker
		window.location.reload();
	}
};
