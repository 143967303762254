import Immutable from 'immutable';
import { UBT_TYPE } from '@ctrip/ubt-web';
import { initVet } from '@ctrip/hetu-react';
import { detectLightHouse } from '../utils';
import getPVData from './getPVData';

const createFlowEventLog = (data) => {
	if (data && typeof data === 'string') {
		// 如果是字符串直接发埋点
		return data;
	}
	return {
		...data,
		__app_resource__: 32271,
	};
};

const initVetFn = ({ pageId }: { pageId?: string }) => {
	const version = `_cwxVersion`;
	initVet({
		pageId,
		extend: {
			handleDataExtend: {
				needHandleData: true,
				handleDataFn: (data, key) => {
					if (
						[
							'hotDistrictList',
							'workingDays',
							'flight',
							'allFlightsToSegmentListDict',
							'allFlightsToItineraryTagEnumsDict',
							'priceList',
						].includes(key)
					) {
						return null;
					} else if (Immutable.isImmutable(data)) {
						return data.toJS();
					} else if (data?._isAMomentObject) {
						return data.format('YYYY-MM-DD');
					} else {
						return data;
					}
				},
			},
		},
		version,
	});
};

const getUbtWebSDK = async () => {
	if (detectLightHouse()) {
		return {
			register: () => {},
			set: () => {},
			send: () => {},
		};
	} else {
		return import('@ctrip/ubt-web').then((ubt) => ubt.default);
	}
};

/**
 * ubt注册的appid
 */
const UBT_APP_ID = 100032271;

/**
 * @name 发送PV
 * @param {string} pageId
 */
export const sendPV = async ({ pageId, firstSegments }: { pageId: string; firstSegments?: any[] }) => {
	const UBT = await getUbtWebSDK();
	const pvId = UBT.register({
		pageId,
		appId: UBT_APP_ID,
		// @ts-ignore
		business: {
			...getPVData(firstSegments),
		},
		settings: {},
	});
	UBT.set({ pageId }, pvId || '');
	initVetFn({ pageId });
	console.debug('%cubtSendPV', 'color:cyan', pageId, pvId);
};

/**
 * @name 发送trace
 * @param {Object} data 埋点数据
 */
const ubtTrace = async (key, data?: any, pvId?: string) => {
	const UBT = await getUbtWebSDK();
	UBT.send(
		{
			type: UBT_TYPE.TRACE,
			key,
			data: createFlowEventLog(data),
		},
		pvId
	);
	console.debug('%cubtTrace', 'color:cyan', key, data, pvId);
};

/**
 * @name 发送曝光
 */
export const ubtExposure = async (key, data?: any, pvId?: string) => {
	const ubt = await getUbtWebSDK();
	ubt.send(
		{
			type: UBT_TYPE.EXPOSURE,
			key,
			data: createFlowEventLog(data),
		},
		pvId
	);
	console.debug('%cubtExposure', 'color:cyan', key, data, pvId);
};

/**
 * @name 发送devTrace
 * @param {Object} data 埋点数据
 */
export const ubtDevTrace = async (key: string, data?: any, pvId?: string) => {
	const UBT = await getUbtWebSDK();
	UBT.send(
		{
			type: UBT_TYPE.DEV_TRACE,
			key: `flt_online_${key}`,
			data: createFlowEventLog(data),
		},
		pvId
	);
	console.debug('%cubtDevTrace', 'color:cyan', key, data, pvId);
};

const enterTime = Date.now();

/**
 * 发送 Error 埋点
 */
export const ubtError = async (error: unknown, pvId?: string) => {
	const UBT = await getUbtWebSDK();

	let message = '';
	let stack: string[] | undefined;

	if (typeof error === 'string') {
		message = error;
	}
	if (error instanceof Error) {
		stack = error?.stack?.split('\n');
		message = error?.message;
	}
	const time = Date.now() - enterTime;

	const data = {
		message, // 错误信息
		time, // JS发生错误的时间（毫秒）基于页面执行时间
		stack, // 错误栈
		file: '', // 错误来源文件
		category: 'inner-error', // 错误分类（Framework-error/Widget-error/Logic-error/inner-error)
		framework: 'normal',
		line: 0, // 错误出现的行数
		column: 0, // 错误出现的列数
		repeat: 1, // 错误发生的次数
	};

	UBT.send(
		{
			type: UBT_TYPE.ERROR,
			data,
		},
		pvId
	);
	console.error('%cubtError', 'color:red', { message, time }, stack);
};

export const sendError = (error: Error | string, pvId?: string) => {
	ubtError(error, pvId);
};

/**
 * @name 发送埋点
 * @param {string} key
 * @param {Object} value
 */
export const sendTrace = (key, value?: any, pvId?: string) => {
	ubtTrace(key, createFlowEventLog(value || {}), pvId);
};

/**
 * @name 发送曝光埋点
 * @param {string} key
 * @param {object} value
 */
export const sendTraceExpose = (key, value?: any, pvId?: string) => {
	ubtExposure(key, createFlowEventLog(value || {}), pvId);
};

export const sendUBT = (key, value, isDev?: boolean, pvId?: string) => {
	if (isDev) {
		ubtDevTrace(key, value, pvId);
	} else {
		ubtTrace(`flight_online_${key}`, value, pvId);
	}
};

export const logTrace = (key, value, pvId?: string) => {
	ubtTrace(key, value, pvId);
};

export const lightUpload = (group, data, pvId?: string) => {
	ubtTrace(group, data, pvId);
};

export const lightUploadSpeed = (data, pvId?: string) => {
	ubtTrace('_flight_online_trace', data, pvId);
};

export const lightSendPv = (pageId, pvId?: string) => {
	ubtTrace(
		'flt_node_sep_list_pv',
		{
			pageId,
		},
		pvId
	);
};

export const sendMetric = async (key, value, pvId?: string) => {
	const UBT = await getUbtWebSDK();
	UBT.send(
		{
			type: UBT_TYPE.METRIC,
			key: `flt_online_${key}`,
			data: {
				value,
			},
		},
		pvId
	);
};

export const sendVetTrace = ({ key, ubtData, pvId }: { key: string; ubtData: any; pvId?: string }) => {
	// 多程暂不发送埋点
	if (window?.GlobalSearchCriteria?.flightWay === 'M') {
		return;
	}
	const newUbtData = {
		...ubtData,
		__vetSwitcher: String(window?.vetSwitcherWeb === 'true'),
	};
	sendTrace(key, newUbtData, pvId);
};

export const sendVetTraceExpose = ({ key, ubtData, pvId }: { key: string; ubtData: any; pvId?: string }) => {
	const newUbtData = {
		...ubtData,
		__vetSwitcher: String(window?.vetSwitcherWeb === 'true'),
	};
	sendTraceExpose(key, newUbtData, pvId);
};
